<template>
  <div>
    <page-header has-back>
      <template v-slot:right>
        <el-button type="primary" @click="handleEdit()">添加银行卡</el-button>
      </template>
    </page-header>
    <div class="layout-main-body">
      <el-card shadow="never">
        <el-row>
          <el-col>
            <el-form :inline="true" :model="searchForm">
              <el-form-item>
                <el-button plain @click="handleCurrentChange(1)">刷新</el-button>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-table
                :data="rows"
                v-loading="loading"
                style="width: 100%">
              <template slot="empty">
                <el-empty description="暂无数据"></el-empty>
              </template>
              <el-table-column type="index" label="#"></el-table-column>
              <el-table-column prop="bankName" label="开户行"></el-table-column>
              <el-table-column prop="bankCardOwner" label="户名"></el-table-column>
              <el-table-column prop="cardNumberText" label="卡号"></el-table-column>
              <el-table-column prop="isDefault" label="默认">
                <template slot-scope="scope">
                  {{ scope.row.isDefault ? '是' : '否' }}
                </template>
              </el-table-column>
              <el-table-column prop="creationTime" label="创建时间" width="140"></el-table-column>
              <el-table-column
                  label="操作"
                  width="200">
                <template slot-scope="scope">
                  <el-button @click="handleRemove([scope.row.id])" type="text">删除</el-button>
                  <el-divider direction="vertical"></el-divider>
                  <el-button @click="handleEdit(scope.row.id)" type="text">编辑</el-button>
                </template>
              </el-table-column>
            </el-table>

            <el-dialog
                title="编辑"
                :visible.sync="dialogVisible"
                width="40%">
              <el-form :model="editForm" :rules="rules" ref="editForm" label-width="80px">
                <el-form-item label="开户行" prop="bankName">
                  <el-select v-model="editForm.bankName">
                    <el-option value="中国建设银行" label="中国建设银行"></el-option>
                    <el-option value="中国农业银行" label="中国农业银行"></el-option>
                    <el-option value="中国工商银行" label="中国工商银行"></el-option>
                    <el-option value="中国银行" label="中国银行"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="户名" prop="bankCardOwner">
                  <el-input v-model="editForm.bankCardOwner"></el-input>
                </el-form-item>
                <el-form-item label="卡号" prop="cardNumber">
                  <el-input v-model="editForm.cardNumber"></el-input>
                </el-form-item>
                <el-form-item label="是否默认">
                  <el-checkbox v-model="editForm.isDefault"></el-checkbox>
                </el-form-item>
              </el-form>
              <div slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false" plain>取消</el-button>
                <el-button type="primary" @click="handleSave" :loading="editLoading">保存</el-button>
              </div>
            </el-dialog>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="page"
                :page-sizes="[10,20,50,100]"
                :page-size="size"
                :background="true"
                layout="total, sizes, prev, pager, next"
                :total="total">
            </el-pagination>
          </el-col>
        </el-row>
      </el-card>
    </div>
  </div>
</template>

<script>
import { AccountBankCardApi } from '@/api'

const defaultForm = () => {
  return {
    bankName: '',
    cardNumber: '',
    bankCardOwner: '',
    isDefault: false
  }
}

export default {
  name: 'BankCard',
  data () {
    return {
      searchForm: {},
      editForm: defaultForm(),
      rules: {
        bankName: [
          {
            required: true,
            message: '请输入开户行',
            trigger: 'blur'
          }
        ],
        cardNumber: [
          {
            required: true,
            message: '请输入银行卡号',
            trigger: 'blur'
          }
        ],
        bankCardOwner: [
          {
            required: true,
            message: '请输入户名',
            trigger: 'blur'
          }
        ]
      },
      rows: [],
      page: 1,
      size: 10,
      total: 0,
      loading: false,
      editLoading: false,
      dialogVisible: false
    }
  },
  methods: {
    handleSearch () {
      this.loading = true
      const params = {
        page: this.page - 1,
        size: this.size
      }
      if (this.searchForm.type !== '0') {
        params.type = this.searchForm.type
      }
      if (this.searchForm.startTime) {
        params.startTime = this.searchForm.startTime
      }
      if (this.searchForm.endTime) {
        params.endTime = this.searchForm.endTime
      }
      if (this.dateRange && this.dateRange.length === 2) {
        params.startTime = this.dateRange[0]
        params.endTime = this.dateRange[1]
      }
      AccountBankCardApi.getList(params).then(result => {
        this.rows = result.rows
        this.total = result.total
        this.loading = false
      })
    },
    handleSizeChange (size) {
      this.size = size
      this.handleSearch()
    },
    handleCurrentChange (page) {
      this.page = page
      this.handleSearch()
    },
    handleTabsClick () {
      this.handleCurrentChange(1)
    },
    handleEdit (id) {
      this.dialogVisible = true
      this.$nextTick(() => {
        this.$refs.editForm.resetFields()
        if (id) {
          AccountBankCardApi.get(id).then(data => {
            Object.assign(this.editForm, data)
          })
        } else {
          this.editForm = defaultForm()
        }
      })
    },
    handleSave () {
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          this.editLoading = true
          AccountBankCardApi.save(this.editForm).then(() => {
            this.editLoading = false
            this.dialogVisible = false
            this.$message.success('保存成功')
            this.handleCurrentChange(1)
          }).catch(() => {
            this.editLoading = false
          })
        } else {
          return false
        }
      })
    },
    handleRemove (id) {
      this.$confirm('确定要删除吗？', '提示', {
        type: 'warning'
      }).then(() => {
        AccountBankCardApi.remove(id).then(() => {
          this.$message.success('删除成功')
          this.handleCurrentChange(1)
        })
      }).catch(() => {})
    }
  },
  created: function () {
    this.handleSearch()
  }
}
</script>

<style scoped>
</style>
